import React, { useState, useEffect, useContext } from "react"

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, TextField, Box, Collapse, FormControl, InputLabel, Typography, CircularProgress, Alert, AlertTitle } from "@mui/material"

import { Formik, Form } from "formik"

import { SlideUpTransition } from "../../core/mui-transitions"

import CollapseAlert from "../../core/CollapseAlert"

import { getPlatformVariables, updatePlatformVariables } from "./data/remote"

import trimObjValues from "../../functions/trimobjectvalues"

import CustomLoader from "../../core/CustomLoader"

import socket from "../../services/socketio"
import { PlatformVariables } from "../../context/DashboardContext/DashboardContext"
import { confirmPIN, requestPIN } from "../../auth"
import PinField from "react-pin-field"
import { borderRadius } from "@mui/system"




export default function useEditPlatformVariables() {

  const [show, setShow] = useState(false)

  const [selectedVar, setselectedVar] = useState({})


  const showEditPlatformVariables = (platformVariable) => {
    setShow(true)
    setselectedVar(platformVariable)
  }


  const closeEditPlatformVariables = () => {
    setShow(false)
  }


  const EditPlatformVariables = ({ onSuccess = () => { } }) => {

    //States

    const [isLoading, setIsLoading] = useState(true)

    const [isRequestingPin, setRequestingPin] = useState(false)

    const [showPinField, setShowPinField] = useState(false)

    const [initValues, setInitValues] = useState({})

    const [pin, setpin] = useState(null)

    const [platformVariables, setPlatformVariables] = useContext(PlatformVariables)


    const [alertState, setAlertState] = useState({
      open: false,
      severity: "success",
      message: "We want to tell you something"
    })


    //Handlers

    const getCurrentPlatformVars = async () => {

      let finalData = {
        billsConvenienceFee: platformVariables.billsConvenienceFee,
        billsOneDirhamToPeso: platformVariables.billsOneDirhamToPeso,
        convenienceFeeInDirham: platformVariables.convenienceFeeInDirham,
        giftConvenienceFee: platformVariables.giftConvenienceFee,
        giftOneDirhamToPeso: platformVariables.giftOneDirhamToPeso,
        oneDirhamToPeso: platformVariables.oneDirhamToPeso,
        topupConvenienceFee: platformVariables.topupConvenienceFee,
        topupOneDirhamToPeso: platformVariables.topupOneDirhamToPeso
      }

      setInitValues(finalData)

      setIsLoading(false)

    }


    const handleRequestPin = async () => {
      try {

        setRequestingPin(true)

        let result = await requestPIN()

        setAlertState((prevState) => ({
          ...prevState,
          open: true,
          severity: "success",
          message: result?.message
        }))

        setRequestingPin(false)

        setShowPinField(true)

      } catch (error) {

        setAlertState((prevState) => ({
          ...prevState,
          open: true,
          severity: "error",
          message: JSON.parse(error.message).error
        }))

      }
    }

    const handlePinChange = (value) => {

      if (alertState.open) {
        setAlertState((prevState) => ({
          ...prevState,
          open: false,
        }))
      }

      setpin(value)
    }




    useEffect(() => {

      getCurrentPlatformVars()

    }, [])


    return (
      <Dialog open={show} onClose={closeEditPlatformVariables} TransitionComponent={SlideUpTransition} fullWidth>
        <DialogTitle>Wallet Conversions / Convenience fees</DialogTitle>
        {isLoading && <CustomLoader />}
        {
          !isLoading &&
          <DialogContent>
            <Formik
              initialValues={initValues}
              onSubmit={async (data) => {

                try {

                  await confirmPIN({ pin: pin })

                  let trimmedData = trimObjValues(data)

                  let result = await updatePlatformVariables(trimmedData)

                  setPlatformVariables(result[0])

                  socket.emit("emit-new-platformvars", result);

                  onSuccess()

                  setAlertState((prevState) => ({
                    ...prevState,
                    open: true,
                    severity: "success",
                    message: "Update success"
                  }))

                  setShowPinField(false)

                } catch (error) {

                  setAlertState((prevState) => ({
                    ...prevState,
                    open: true,
                    severity: "error",
                    message: JSON.parse(error.message).error || JSON.parse(error.message).message
                  }))
                }


              }}
            >
              {({ isSubmitting, handleChange, values, handleSubmit }) => {
                return (
                  <Form>
                    <Box sx={{ height: 5 }} />
                    <Stack spacing={2}>
                      <TextField
                        style={{
                          display: selectedVar === "billsOneDirhamToPeso" ? "" : "none"
                        }}
                        name={"billsOneDirhamToPeso"}
                        label={"Bills one dirham to peso"}
                        type={"number"}
                        value={values.billsOneDirhamToPeso}
                        onChange={handleChange}
                        disabled={showPinField}
                      />
                      <TextField
                        style={{
                          display: selectedVar === "topupOneDirhamToPeso" ? "" : "none"
                        }}
                        name={"topupOneDirhamToPeso"}
                        label={"Topup one dirham to peso"}
                        type={"number"}
                        value={values.topupOneDirhamToPeso}
                        onChange={handleChange}
                        disabled={showPinField}
                      />
                      <TextField
                        style={{
                          display: selectedVar === "giftOneDirhamToPeso" ? "" : "none"
                        }}
                        name={"giftOneDirhamToPeso"}
                        label={"Gifts one dirham to peso"}
                        type={"number"}
                        value={values.giftOneDirhamToPeso}
                        onChange={handleChange}
                        disabled={showPinField}
                      />
                      <TextField
                        style={{
                          display: selectedVar === "billsConvenienceFee" ? "" : "none"
                        }}
                        name={"billsConvenienceFee"}
                        label={"Bills convenience fee"}
                        type={"number"}
                        value={values.billsConvenienceFee}
                        onChange={handleChange}
                        disabled={showPinField}
                      />
                      <TextField
                        style={{
                          display: selectedVar === "giftConvenienceFee" ? "" : "none"
                        }}
                        name={"giftConvenienceFee"}
                        label={"Gifts convenience fee"}
                        type={"number"}
                        value={values.giftConvenienceFee}
                        onChange={handleChange}
                        disabled={showPinField}
                      />
                      <TextField
                        style={{
                          display: selectedVar === "topupConvenienceFee" ? "" : "none"
                        }}
                        name={"topupConvenienceFee"}
                        label={"Topup convenience fee"}
                        type={"number"}
                        value={values.topupConvenienceFee}
                        onChange={handleChange}
                        disabled={showPinField}
                      />
                      <CollapseAlert
                        open={alertState.open}
                        severity={alertState.severity}
                        message={alertState.message}
                        onClose={() => {
                          setAlertState((prevState) => ({
                            ...prevState,
                            open: false,
                            severity: "warning",
                            message: "You need higher access"
                          }))
                        }}
                      />
                      <Collapse in={showPinField}>
                        <Typography>Input PIN</Typography>
                        <Stack direction={"row"} alignItems={"center"}>
                          <PinField
                            required
                            name={"pin"}
                            length={5}
                            onChange={handlePinChange}
                            onComplete={handleSubmit}
                            style={{
                              textAlign: "center",
                              width: "50px",
                              height: "50px",
                              border: "solid 0.1px gray",
                              margin: "1px",
                            }}
                          />
                          {isSubmitting ? <CircularProgress /> : <div />}
                        </Stack>
                        <Box sx={{ height: "10px" }} />
                        <Alert severity="info">
                          <AlertTitle>Info</AlertTitle>
                          Type the PIN you recieve via email to confirm and save —
                          <Button onClick={handleRequestPin}>
                            <strong>{isRequestingPin ? "Please wait..." : "Didn't recieve an email? click here"}</strong>
                          </Button>
                        </Alert>

                      </Collapse>
                    </Stack>

                    <DialogActions>
                      {
                        !showPinField &&
                        <Button disabled={isRequestingPin} onClick={handleRequestPin}>{isRequestingPin ? "Please wait..." : "SAVE"}</Button>
                      }
                    </DialogActions>
                  </Form>
                )
              }}
            </Formik>
          </DialogContent>
        }
      </Dialog>
    )

  }

  return { showEditPlatformVariables, closeEditPlatformVariables, EditPlatformVariables }
}