import React, { useEffect, useState } from 'react'

import { Box, Button, Card, CardContent, Stack } from '@mui/material'
import DataTable from 'react-data-table-component'
import { getAllStoreEnv } from '../../data/remote/StoreEnv';
import moment from 'moment';
import useNewStoreEnvDialog from '../../hook-components/useNewStoreEnvDialog';
import useUpdateStoreEnvDialog from '../../hook-components/useUpdateStoreEnvDialog';
import CustomLoader from '../../../../core/CustomLoader';
import useViewStoreEnvDialog from '../../hook-components/useViewStoreEnvDialog';

const StoreEnvPage = () => {

  const [isLoading, setisLoading] = useState(true)

  const [data, setData] = useState([])

  const [renderData, setrenderData] = useState([])

  const [refresh, setRefresh] = useState(false)

  const { showNewStoreEnvDialog, NewStoreEnvDialog } = useNewStoreEnvDialog()

  const { showViewStoreEnvDialog, ViewStoreEnvDialog } = useViewStoreEnvDialog()





  const initialLoad = async () => {
    try {

      let result = await getAllStoreEnv()

      setisLoading(false)

      setData(result)

    } catch (error) {

    }
  }

  useEffect(() => {
    initialLoad()
  }, [refresh])

  useEffect(() => {
    setrenderData(data)
  }, [data])


  const columns = [
    {
      id: 'createdAt',
      name: 'Created At',
      selector: row => row.createdAt,
      sortable: true,
      cell: row => <span>{moment(row.createdAt
      ).tz('Asia/Manila').format("YYYY MMMM DD - hh:mm:ss a")}
      </span>,
    },
    {
      id: 'name',
      name: 'Name',
      selector: row => row.environmentName,
    },
    {
      id: 'currency',
      name: 'Currency',
      selector: row => row.currencySymbol,
    },
    {
      id: 'actions',
      name: 'Action',
      selector: row => (
        <Button onClick={() => {
          showViewStoreEnvDialog(row)
        }}>View / Update</Button>
      ),

    },

  ];

  return (
    <Box>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Button variant='contained'
              onClick={showNewStoreEnvDialog}
            >New Environment</Button>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <DataTable
              pagination
              data={renderData}
              columns={columns}
              progressPending={isLoading}
              progressComponent={<CustomLoader />}
            />
          </CardContent>
        </Card>
      </Stack>
      <NewStoreEnvDialog onSuccess={() => {
        setRefresh(!refresh)
      }} />
      <ViewStoreEnvDialog onSuccess={() => {
        setRefresh(!refresh)
      }} />
    </Box>
  )
}

export default StoreEnvPage