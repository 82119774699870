import { API } from '../../../../config';


export const updatePlatformVariables = async (updateData) => {

  return await fetch(`${API}/auth/platform-variables`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateData)
  })
    .then(async (response) => {
      if (response.status !== 200) {
        let jsonData = await response.json()
        throw Error(JSON.stringify(jsonData))
      }
      return response.json()
    })
    .catch((err) => {

      throw err
    })

}


export const getPlatformVariables = async () => {
  return await fetch(`${API}/auth/platform-variables`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {

      if (response.status !== 200) {
        let jsonData = await response.json()
        throw Error(JSON.stringify(jsonData))
      }

      return response.json()
    })
    .catch((err) => {
      throw err
    })

}