import React, { useState, useEffect } from "react"
import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { SlideUpTransition } from "../../../core/mui-transitions"

import { Form, Formik } from "formik"
import { createStore } from "../data/remote/Stores"
import { StoreTypes } from "../constants/constants"
import { getAllStoreEnv } from "../data/remote/StoreEnv";

export default function useNewStoreDialog() {



  const [show, setshow] = useState(false)

  const showNewStoreDialog = () => {
    setshow(true)
  }

  const closeNewStoreDialog = () => {
    setshow(false)
  }

  const NewStoreDialog = ({ onSuccess = () => { } }) => {

    const [isLoading, setisLoading] = useState(true)

    const [storeEnvList, setstoreEnvList] = useState([])

    const [alertState, setalertState] = useState({
      open: false,
      severity: "success",
      message: "Alert message"
    })

    const initialFunction = async () => {
      let result = await getAllStoreEnv()
      setisLoading(false)
      setstoreEnvList(result)
    }

    useEffect(() => {
      initialFunction()
    }, [])


    return (
      <Dialog open={show} onClose={closeNewStoreDialog} TransitionComponent={SlideUpTransition} fullWidth>
        <DialogContent>
          <Typography variant={"h5"}>New Store</Typography>
        </DialogContent>
        <Formik
          initialValues={{
            type: "STORE",
            storeEnv: "62cc4920d4ebed1ac433dc58"
          }}
          onSubmit={async (data) => {
            try {

              let storeparams = {
                name: data?.name,
                trn: data?.trn,
                type: data?.type,
                address: `${data?.addressl1.trim()}|${data?.city.trim()}|${data?.state.trim()}|${data?.zipcode.trim()}`,
                storeEnv: data?.storeEnv,
                walletLimit: data?.walletLimit,
              }

              let response = await createStore(storeparams)

              if (response.status === 200) {
                onSuccess()

                closeNewStoreDialog()
              }

            } catch (error) {
              setalertState((prevState) => ({
                ...prevState,
                open: true,
                severity: "error",
                message: error.message
              }))
            }
          }}>{({ handleChange, isSubmitting, values }) => {
            return (
              <DialogContent>
                <Form>
                  <Stack spacing={2}>
                    <TextField
                      required
                      name={"name"}
                      label="Store name"
                      placeholder="Enter store name"
                      onChange={handleChange}
                    />
                    <FormControl required>
                      <InputLabel id="demo-simple-select-helper-label">
                        {"Store Type"}
                      </InputLabel>
                      <Select
                        value={values.type}
                        required
                        name={"type"}
                        label={"Store type"}
                        placeholder={"Select store type"}
                        onChange={handleChange}
                      >
                        {StoreTypes.map((v) => (
                          <MenuItem value={v.split(":")[0]}>{v.split(":")[1]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      name={"trn"}
                      label="Store TRN"
                      placeholder="Enter store TRN"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      name={"addressl1"}
                      label="Address line 1"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      name={"city"}
                      label="City"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      name={"state"}
                      label="State"
                      onChange={handleChange}
                    />
                    <TextField
                      required
                      name={"zipcode"}
                      label="Zip code"
                      onChange={handleChange}
                    />
                    <FormControl required>
                      <InputLabel id="demo-simple-select-helper-label">
                        {"Store Environment"}
                      </InputLabel>
                      <Select
                        value={values.storeEnv}
                        required
                        name={"storeEnv"}
                        label={"Store Environment"}
                        placeholder={"Select store environment"}
                        onChange={handleChange}
                      >
                        {storeEnvList.map((v) => (
                          <MenuItem value={v._id}>{v.environmentName} - {v.currency}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      name={"walletLimit"}
                      label="Wallet Limit"
                      placeholder={`100.00 ${
                        storeEnvList.map((v) => {
                          if(v._id === values.storeEnv) {
                            return v.currency;
                          } else {
                            return ""
                          }
                          // remove other array on string render
                        })
                      }`}
                      helperText={`Following the currency of Store Env - ${
                        storeEnvList.map((v) => {
                          if(v._id === values.storeEnv) {
                            return v.currency;
                          } else {
                            return ""
                          }
                          // remove other array on string render
                        })
                      }, if unlimited leave blank`}
                      onChange={handleChange}
                    />

                    <Collapse in={alertState.open}>
                      <Alert
                        severity={alertState.severity}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setalertState((prevState) => ({
                                ...prevState,
                                open: false,
                                severity: "success",
                                message: ""
                              }))
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        sx={{ mb: 2 }}
                      >
                        {alertState.message}
                      </Alert>
                    </Collapse>

                  </Stack>
                  <DialogActions>
                    <Button disabled={isSubmitting || isLoading} type={"submit"}>{isSubmitting || isLoading ? "Please wait..." : "CREATE STORE"}</Button>
                  </DialogActions>
                </Form>
              </DialogContent>
            )
          }}</Formik>

      </Dialog >
    )
  }

  return { showNewStoreDialog, closeNewStoreDialog, NewStoreDialog }
}