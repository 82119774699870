import React, { useState, useEffect } from 'react'

import StoreStatusChip from "../../components/StoreStatusChip"

import { Box, Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import DataTable from 'react-data-table-component'
import CustomLoader from '../../../../core/CustomLoader';

import moment from 'moment-timezone';

import { getAllStores } from '../../data/remote/Stores'
import useNewStoreDialog from '../../hook-components/useNewStoreDialog';
import useUpdateStoreDialog from '../../hook-components/useUpdateStoreDialog';
import useStoreViewer from '../../hook-components/useStoreViewer';
import { StoreTypes } from '../../constants/constants';

const AllStores = () => {


  const [isLoading, setisLoading] = useState(true)

  const [refresh, setRefresh] = useState(false)

  const [data, setdata] = useState([])

  const [renderData, setrenderData] = useState([])

  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  //HOOK COMPONENT

  const { showNewStoreDialog, closeNewStoreDialog, NewStoreDialog } = useNewStoreDialog()

  const { showUpdateStoreDialog, closeUpdateStoreDialog, UpdateStoreDialog } = useUpdateStoreDialog()

  const { showStoreViewer, closeStoreViewer, StoreViewer } = useStoreViewer()

  useEffect(() => {
    setisLoading(true)
    getAllStores().then((result) => {
      setdata(result)
      setisLoading(false)
    })
  }, [refresh])

  useEffect(() => {
    setrenderData(data)
  }, [data])



  const columns = [
    {
      id: 'status',
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      cell: row => (
        <StoreStatusChip status={row.status} />
      ),
    },
    {
      id: 'trn',
      name: 'TRN',
      selector: row => row.trn,
      sortable: true,
      cell: row => (
        <span>{row.trn ? row.trn : 'No TRN found!'}</span>
      ),
    },
    {
      id: 'createdAt',
      name: 'Created At',
      selector: row => row.createdAt,
      sortable: true,
      cell: row => <span>{moment(row.createdAt
      ).tz('Asia/Manila').format("YYYY MMMM DD - hh:mm:ss a")}
      </span>,
    },
    {
      id: 'name',
      name: 'Store name',
      selector: row => row.name,
    },
    {
      id: 'type',
      name: 'type',
      selector: row => row.type,
      sortable: true,
    },
    {
      id: 'address',
      name: 'Store Address',
      sortable: true,
      grow: 2,
      cell: row => row.address.replaceAll("|", " ")
    },
    {
      id: 'actions',
      name: 'Action',
      selector: row => (
        <Button onClick={() => {
          showUpdateStoreDialog(row)
        }}>Update</Button>
      ),
      sortable: true,
    },
    {
      id: 'view',
      name: 'View',
      selector: row => (
        <Button onClick={() => {
          showStoreViewer(row)
        }}>View</Button>
      ),
      sortable: true,
    },
  ];

  return (
    <Box>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Box>
                <Button variant={'contained'} onClick={() => {
                  showNewStoreDialog()
                }}>NEW STORE</Button>
              </Box>
              <Stack direction={"row"} spacing={2}>
                {/* <TextField label="Search store name"
                  onChange={(e) => {
                    if (data.length > 0) {
                      setResetPaginationToggle(false)

                      let filterData = data.filter(
                        store => store?.name.toLowerCase().includes(e.target.value.toLowerCase())
                      )

                      setrenderData(filterData)
                    }
                  }} /> */}
                <FormControl sx={{ width: "15em" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {"Select store type"}
                  </InputLabel>
                  <Select
                    defaultValue={"all"}
                    name={"type"}
                    label={"Select store type"}
                    placeholder={"Select store type"}
                    onChange={(e) => {
                      if (data.length > 0) {
                        setResetPaginationToggle(false)

                        if (e.target.value === "all") {
                          setrenderData(data)
                          return
                        }


                        let filterData = data.filter(
                          store => store?.type.toLowerCase().includes(e.target.value.toLowerCase())
                        )

                        setrenderData(filterData)
                      }
                    }}
                  >
                    <MenuItem defaultChecked value={"all"}>
                      <em>All</em>
                    </MenuItem>
                    {StoreTypes.map((v) => (
                      <MenuItem value={v.split(":")[0]}>{v.split(":")[1]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <DataTable
              columns={columns}
              data={renderData}
              pagination
              defaultSortFieldId={'createdAt'}
              progressPending={isLoading}
              progressComponent={<CustomLoader />}
              paginationResetDefaultPage={resetPaginationToggle}
            />
          </CardContent>
        </Card>
      </Stack>
      <NewStoreDialog onSuccess={() => {
        setRefresh(!refresh)
      }} />
      <UpdateStoreDialog onSuccess={() => {
        setRefresh(!refresh)
      }} />
      <StoreViewer />
    </Box >
  )
}

export default AllStores