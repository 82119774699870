
import React, { useState } from 'react'

import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { SlideUpTransition } from '../../../core/mui-transitions'
import { Form, Formik } from 'formik'
import CollapseAlert from '../../../core/CollapseAlert'
import { createStoreEnv } from '../data/remote/StoreEnv'
import { currencies } from '../data/local/currencies'

export default function useNewStoreEnvDialog() {

  const [show, setShow] = useState(false)

  const showNewStoreEnvDialog = () => {
    setShow(true)
  }

  const closeNewStoreEnvDialog = () => {
    setShow(false)
  }






  const NewStoreEnvDialog = ({ onSuccess = () => { } }) => {


    const [alertState, setAlertState] = useState({
      open: false,
      severity: "success",
      message: "Message"
    })








    return <Dialog open={show} onClose={() => {
      closeNewStoreEnvDialog()
      onSuccess()
    }} fullWidth TransitionComponent={SlideUpTransition}>
      <DialogContent>
        <Formik
          initialValues={{
            enableLoad: false,
            enableBills: false,
            enableGift: false
          }}
          onSubmit={async (data) => {
            try {
              await createStoreEnv(data)

              setAlertState((prevState) => ({
                ...prevState,
                open: true,
                severity: 'success',
                message: 'New store environment created'
              }))
            } catch (error) {
              setAlertState((prevState) => ({
                ...prevState,
                open: true,
                severity: 'error',
                message: error.message
              }))
            }
          }}
        >{({ values, handleChange, isSubmitting }) => {
          return (
            <Form>
              <Stack spacing={2}>
                <Typography variant={"h5"}>New Store Environment</Typography>
                <TextField
                  required
                  name={"environmentName"}
                  label={"Environment Name"}
                  value={values.environmentName}
                  onChange={handleChange}
                />
                <FormControl required>
                  <InputLabel id="demo-simple-select-helper-label">
                    {"Currency symbol"}
                  </InputLabel>
                  <Select
                    value={values.currencySymbol}
                    required
                    name={"currencySymbol"}
                    label={"Currency symbol"}
                    placeholder={"Select currency symbol"}
                    onChange={handleChange}
                  >
                    {currencies.map((v) => (
                      <MenuItem value={v.cc}>{`(${v.cc}) ${v.name}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  type={"number"}
                  name={"billsCurrencyToPeso"}
                  label={"Bills Currency To Peso"}
                  value={values.billsCurrencyToPeso}
                  onChange={handleChange}
                />
                <TextField
                  required
                  type={"number"}
                  name={"topupCurrencyToPeso"}
                  label={"Topup Currency To Peso"}
                  value={values.topupCurrencyToPeso}
                  onChange={handleChange}
                />
                <TextField
                  required
                  type={"number"}
                  name={"giftCurrencyToPeso"}
                  label={"Gift Currency To Peso"}
                  value={values.giftCurrencyToPeso}
                  onChange={handleChange}
                />
                <TextField
                  required
                  type={"number"}
                  name={"billsConvenienceFee"}
                  label={"Bills Convenience Fee"}
                  value={values.billsConvenienceFee}
                  onChange={handleChange}
                />
                <TextField
                  required
                  type={"number"}
                  name={"topupConvenienceFee"}
                  label={"Topup Convenience Fee"}
                  value={values.topupConvenienceFee}
                  onChange={handleChange}
                />
                <TextField
                  required
                  type={"number"}
                  name={"giftConvenienceFee"}
                  label={"Gift Convenience Fee"}
                  value={values.giftConvenienceFee}
                  onChange={handleChange}
                />
                <FormControlLabel
                  label="Topup service"
                  control={
                    <Checkbox
                      name={'enableLoad'}
                      checked={values.enableLoad}
                      onChange={handleChange}
                    />
                  }
                />
                <FormControlLabel
                  label="Bills payment service"
                  control={
                    <Checkbox
                      name={'enableBills'}
                      checked={values.enableBills}
                      onChange={handleChange}
                    />
                  }
                />
                <FormControlLabel
                  label="Voucher / Gift service"
                  control={
                    <Checkbox
                      name={'enableGift'}
                      checked={values.enableGift}
                      onChange={handleChange}
                    />
                  }
                />
                <CollapseAlert
                  open={alertState.open}
                  severity={alertState.severity}
                  message={alertState.message}
                  onClose={() => {
                    setAlertState((prevState) => ({ ...prevState, open: false }))
                  }}
                />
                <DialogActions>
                  <Button type={'submit'} disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "CREATE STORE ENV"}</Button>
                </DialogActions>
              </Stack>

            </Form>
          )
        }}</Formik>

      </DialogContent>
    </Dialog >
  }


  return { showNewStoreEnvDialog, closeNewStoreEnvDialog, NewStoreEnvDialog }


}