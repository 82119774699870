import { API } from "../../../../config"

export const getAllStoreEnv = async () => {

  return await fetch(`${API}/storeenv/list/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to get stores")
      }
      return response.json()
    })
    .catch((err) => {

      return err
    })
}


export const createStoreEnv = async ({
  environmentName,
  currencySymbol,
  billsCurrencyToPeso,
  topupCurrencyToPeso,
  giftCurrencyToPeso,
  billsConvenienceFee,
  topupConvenienceFee,
  giftConvenienceFee,
  enableBills,
  enableGift,
  enableLoad,
}) => {

  const { token } = JSON.parse(localStorage.getItem("jwt"));


  let reqBody = {
    "environmentName": environmentName,
    "currencySymbol": currencySymbol,
    "billsCurrencyToPeso": billsCurrencyToPeso,
    "topupCurrencyToPeso": topupCurrencyToPeso,
    "giftCurrencyToPeso": giftCurrencyToPeso,
    "billsConvenienceFee": billsConvenienceFee,
    "topupConvenienceFee": topupConvenienceFee,
    "giftConvenienceFee": giftConvenienceFee,
    "enableBills": enableBills,
    "enableGift": enableGift,
    "enableLoad": enableLoad,
  }


  return await fetch(`${API}/storeenv/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify(reqBody)
  })
    .then(async (response) => {
      if (response.status !== 200) {
        let r = await response.json()
        throw Error(r.error)
      }
      return response
    })
    .catch((err) => {
      throw err
    })
}


export const updateStoreEnv = async ({
  storeEnvId,
  environmentName,
  currencySymbol,
  billsCurrencyToPeso,
  topupCurrencyToPeso,
  giftCurrencyToPeso,
  billsConvenienceFee,
  topupConvenienceFee,
  giftConvenienceFee,
  enableBills,
  enableGift,
  enableLoad,
}) => {

  const { token } = JSON.parse(localStorage.getItem("jwt"));


  let reqBody = {
    "environmentName": environmentName,
    "currencySymbol": currencySymbol,
    "billsCurrencyToPeso": billsCurrencyToPeso,
    "topupCurrencyToPeso": topupCurrencyToPeso,
    "giftCurrencyToPeso": giftCurrencyToPeso,
    "billsConvenienceFee": billsConvenienceFee,
    "topupConvenienceFee": topupConvenienceFee,
    "giftConvenienceFee": giftConvenienceFee,
    "enableBills": enableBills,
    "enableGift": enableGift,
    "enableLoad": enableLoad,
  }


  return await fetch(`${API}/storeenv/update/${storeEnvId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify(reqBody)
  })
    .then(async (response) => {
      if (response.status !== 200) {
        let r = await response.json()
        throw Error(r.error)
      }
      return response.json()
    })
    .catch((err) => {
      return err
    })
}

export const getStoreEnvById = async ({ storeEnvId }) => {

  return await fetch(`${API}/storeenv/id/${storeEnvId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to get stores")
      }
      return response.json()
    })
    .catch((err) => {

      return err
    })
}






