
import React, { useState, useEffect } from 'react'

import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import { SlideUpTransition } from '../../../core/mui-transitions'
import { Form, Formik } from 'formik'
import CollapseAlert from '../../../core/CollapseAlert'
import { getStoreEnvById } from '../data/remote/StoreEnv'
import useUpdateStoreEnvDialog from './useUpdateStoreEnvDialog'

export default function useViewStoreEnvDialog() {

  const [show, setShow] = useState(false)

  const [selectedEnv, setselectedEnv] = useState({})

  const showViewStoreEnvDialog = (data) => {
    setShow(true)
    setselectedEnv(data)
  }

  const closeViewStoreEnvDialog = () => {
    setShow(false)
  }






  const ViewStoreEnvDialog = ({ onSuccess = () => { } }) => {

    const { showUpdateStoreEnvDialog, UpdateStoreEnvDialog } = useUpdateStoreEnvDialog()

    const [refresh, setrefresh] = useState(false)

    const [localSelectedEnv, setlocalSelectedEnv] = useState(selectedEnv)









    return <Dialog open={show} onClose={() => {
      closeViewStoreEnvDialog()
      onSuccess()
    }} fullWidth TransitionComponent={SlideUpTransition}>
      <DialogContent>
        <Formik
          initialValues={localSelectedEnv}
          onSubmit={async (data) => {
            // try {
            //   await updateStoreEnv({ storeEnvId: selectedEnv._id, ...data })

            //   setAlertState((prevState) => ({
            //     ...prevState,
            //     open: true,
            //     severity: 'success',
            //     message: 'Update store environment created'
            //   }))
            // } catch (error) {
            //   setAlertState((prevState) => ({
            //     ...prevState,
            //     open: true,
            //     severity: 'error',
            //     message: error.message
            //   }))
            // }
          }}
        >{({ values, handleChange, isSubmitting }) => {
          return (
            <Form>
              <Stack spacing={2}>
                <Typography variant={"h5"}>{localSelectedEnv.environmentName}</Typography>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Environment name"}</Typography>
                    <Typography>{localSelectedEnv.environmentName}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "environmentName"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Currency"}</Typography>
                    <Typography>{localSelectedEnv.currencySymbol}</Typography>

                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "currency"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Bills currency to peso"}</Typography>
                    <Typography>{localSelectedEnv.billsCurrencyToPeso}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "billsCurrencyToPeso"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Topup currency to peso"}</Typography>
                    <Typography>{localSelectedEnv.topupCurrencyToPeso}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "topupCurrencyToPeso"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Gift currency to peso"}</Typography>
                    <Typography>{localSelectedEnv.giftCurrencyToPeso}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "giftCurrencyToPeso"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Bills convenience fee"}</Typography>
                    <Typography>{localSelectedEnv.billsConvenienceFee}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "billsConvenienceFee"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Topup convenience fee"}</Typography>
                    <Typography>{localSelectedEnv.topupConvenienceFee}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "topupConvenienceFee"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Gift convenience fee"}</Typography>
                    <Typography>{localSelectedEnv.giftConvenienceFee}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "giftConvenienceFee"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Load service"}</Typography>
                    <Typography>{localSelectedEnv.enableLoad ? "Enabled" : "Disabled"}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "enableLoad"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Bills payment service"}</Typography>
                    <Typography>{localSelectedEnv.enableBills ? "Enabled" : "Disabled"}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "enableBills"
                    })
                  }}>Edit</Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack>
                    <Typography fontWeight={"bold"}>{"Gift / Voucher service"}</Typography>
                    <Typography>{localSelectedEnv.enableGift ? "Enabled" : "Disabled"}</Typography>
                  </Stack>
                  <Button onClick={() => {
                    showUpdateStoreEnvDialog({
                      storeEnvData: localSelectedEnv,
                      fieldSelected: "enableGift"
                    })
                  }}>Edit</Button>
                </Stack>
              </Stack>

            </Form>
          )
        }}</Formik>
        <UpdateStoreEnvDialog
          onSuccess={async () => {
            let result = await getStoreEnvById({ storeEnvId: selectedEnv?._id })

            setlocalSelectedEnv(result)
          }}
        />

      </DialogContent>
    </Dialog >
  }


  return { showViewStoreEnvDialog, closeViewStoreEnvDialog, ViewStoreEnvDialog }


}