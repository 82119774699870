import React, { useState } from "react";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SlideUpTransition } from "../../../core/mui-transitions";
import { Form, Formik } from "formik";
import CollapseAlert from "../../../core/CollapseAlert";
import { createStoreEnv, updateStoreEnv } from "../data/remote/StoreEnv";
import { currencies } from "../data/local/currencies";
import { confirmPIN, requestPIN } from "../../../auth";
import PinField from "react-pin-field";

export default function useUpdateStoreEnvDialog() {
  const [show, setShow] = useState(false);

  const [selectedEnv, setselectedEnv] = useState({});

  const [selectedField, setselectedField] = useState({});

  const showUpdateStoreEnvDialog = ({ storeEnvData, fieldSelected }) => {
    setShow(true);
    setselectedEnv(storeEnvData);
    setselectedField(fieldSelected);
  };

  const closeUpdateStoreEnvDialog = () => {
    setShow(false);
  };

  const UpdateStoreEnvDialog = ({ onSuccess = () => {} }) => {
    const [isRequestingPin, setRequestingPin] = useState(false);

    const [showPinField, setShowPinField] = useState(false);

    const [pin, setpin] = useState(null);

    const [alertState, setAlertState] = useState({
      open: false,
      severity: "success",
      message: "Message",
    });

    const handleRequestPin = async () => {
      try {
        setRequestingPin(true);

        let result = await requestPIN();

        setAlertState((prevState) => ({
          ...prevState,
          open: true,
          severity: "success",
          message: result?.message,
        }));

        setRequestingPin(false);

        setShowPinField(true);
      } catch (error) {
        console.log(error);
        setAlertState((prevState) => ({
          ...prevState,
          open: true,
          severity: "error",
          message: error?.message,
        }));
      }
    };

    const handlePinChange = (value) => {
      if (alertState.open) {
        setAlertState((prevState) => ({
          ...prevState,
          open: false,
        }));
      }

      setpin(value);
    };

    return (
      <Dialog
        open={show}
        onClose={() => {
          closeUpdateStoreEnvDialog();
          onSuccess();
        }}
        fullWidth
        TransitionComponent={SlideUpTransition}
      >
        <DialogContent>
          <Formik
            initialValues={selectedEnv}
            onSubmit={async (data) => {
              try {
                console.log(data);

                await confirmPIN({ pin: pin });

                await updateStoreEnv({ storeEnvId: selectedEnv._id, ...data });

                setAlertState((prevState) => ({
                  ...prevState,
                  open: true,
                  severity: "success",
                  message: "Update store environment created",
                }));
              } catch (error) {
                setAlertState((prevState) => ({
                  ...prevState,
                  open: true,
                  severity: "error",
                  message:
                    JSON.parse(error.message).error ||
                    JSON.parse(error.message).message,
                }));
              }
            }}
          >
            {({ values, handleChange, isSubmitting, handleSubmit }) => {
              return (
                <Form>
                  <Stack spacing={2}>
                    <Typography variant={"h5"}>
                      Update Store Environment
                    </Typography>
                    <TextField
                      style={{
                        display:
                          selectedField === "environmentName" ? "" : "none",
                      }}
                      required
                      name={"environmentName"}
                      label={"Environment Name"}
                      value={values.environmentName}
                      onChange={handleChange}
                    />
                    <FormControl
                      style={{
                        display: selectedField === "currency" ? "" : "none",
                      }}
                      required
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        {"Currency symbol"}
                      </InputLabel>
                      <Select
                        value={values.currencySymbol}
                        required
                        name={"currencySymbol"}
                        label={"Currency symbol"}
                        placeholder={"Select currency symbol"}
                        onChange={handleChange}
                      >
                        {currencies.map((v) => (
                          <MenuItem
                            value={v.cc}
                          >{`(${v.cc}) ${v.name}`}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      style={{
                        display:
                          selectedField === "billsCurrencyToPeso" ? "" : "none",
                      }}
                      required
                      type={"number"}
                      name={"billsCurrencyToPeso"}
                      label={"Bills Currency To Peso"}
                      value={values.billsCurrencyToPeso}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{
                        display:
                          selectedField === "topupCurrencyToPeso" ? "" : "none",
                      }}
                      required
                      type={"number"}
                      name={"topupCurrencyToPeso"}
                      label={"Topup Currency To Peso"}
                      value={values.topupCurrencyToPeso}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{
                        display:
                          selectedField === "giftCurrencyToPeso" ? "" : "none",
                      }}
                      required
                      type={"number"}
                      name={"giftCurrencyToPeso"}
                      label={"Gift Currency To Peso"}
                      value={values.giftCurrencyToPeso}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{
                        display:
                          selectedField === "billsConvenienceFee" ? "" : "none",
                      }}
                      required
                      type={"number"}
                      name={"billsConvenienceFee"}
                      label={"Bills Convenience Fee"}
                      value={values.billsConvenienceFee}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{
                        display:
                          selectedField === "topupConvenienceFee" ? "" : "none",
                      }}
                      required
                      type={"number"}
                      name={"topupConvenienceFee"}
                      label={"Topup Convenience Fee"}
                      value={values.topupConvenienceFee}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{
                        display:
                          selectedField === "giftConvenienceFee" ? "" : "none",
                      }}
                      required
                      type={"number"}
                      name={"giftConvenienceFee"}
                      label={"Gift Convenience Fee"}
                      value={values.giftConvenienceFee}
                      onChange={handleChange}
                    />
                    <FormControlLabel
                      style={{
                        display: selectedField === "enableLoad" ? "" : "none",
                      }}
                      label="Topup service"
                      control={
                        <Checkbox
                          name={"enableLoad"}
                          checked={values.enableLoad}
                          onChange={handleChange}
                        />
                      }
                    />
                    <FormControlLabel
                      style={{
                        display: selectedField === "enableBills" ? "" : "none",
                      }}
                      label="Bills payment service"
                      control={
                        <Checkbox
                          name={"enableBills"}
                          checked={values.enableBills}
                          onChange={handleChange}
                        />
                      }
                    />
                    <FormControlLabel
                      style={{
                        display: selectedField === "enableGift" ? "" : "none",
                      }}
                      label="Voucher / Gift service"
                      control={
                        <Checkbox
                          name={"enableGift"}
                          checked={values.enableGift}
                          onChange={handleChange}
                        />
                      }
                    />
                    <CollapseAlert
                      open={alertState.open}
                      severity={alertState.severity}
                      message={alertState.message}
                      onClose={() => {
                        setAlertState((prevState) => ({
                          ...prevState,
                          open: false,
                        }));
                      }}
                    />
                    {/* <DialogActions>
                  <Button type={'submit'} disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "UPDATE STORE ENV"}</Button>
                </DialogActions> */}
                    <Collapse in={showPinField}>
                      <Typography>Input PIN</Typography>
                      <Stack direction={"row"} alignItems={"center"}>
                        <PinField
                          required
                          name={"pin"}
                          length={5}
                          onChange={handlePinChange}
                          onComplete={handleSubmit}
                          style={{
                            textAlign: "center",
                            width: "50px",
                            height: "50px",
                            border: "solid 0.1px gray",
                            margin: "1px",
                          }}
                        />
                        {isSubmitting ? <CircularProgress /> : <div />}
                      </Stack>
                      <Box sx={{ height: "10px" }} />
                      <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        Type the PIN you recieve via email to confirm and save —
                        <Button onClick={handleRequestPin}>
                          <strong>
                            {isRequestingPin
                              ? "Please wait..."
                              : "Didn't recieve an email? click here"}
                          </strong>
                        </Button>
                      </Alert>
                    </Collapse>

                    <DialogActions>
                      {!showPinField && (
                        <Button
                          disabled={isRequestingPin}
                          onClick={handleRequestPin}
                        >
                          {isRequestingPin ? "Please wait..." : "SAVE"}
                        </Button>
                      )}
                    </DialogActions>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  };

  return {
    showUpdateStoreEnvDialog,
    closeUpdateStoreEnvDialog,
    UpdateStoreEnvDialog,
  };
}
