import { API } from '../../../../config';

export const getAllStores = async () => {

  return await fetch(`${API}/store/list/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to get stores")
      }
      return response.json()
    })
    .catch((err) => {

      return err
    })
}


export const createStore = async ({ name, type, address, trn, storeEnv, walletLimit }) => {

  const { token } = JSON.parse(localStorage.getItem("jwt"));


  let reqBody = {
    "name": name,
    "type": type,
    "trn": trn,
    "address": address,
    "storeEnv": storeEnv,
    "walletLimit": walletLimit
  }


  return await fetch(`${API}/store/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify(reqBody)
  })
    .then(async (response) => {
      if (response.status !== 200) {
        let r = await response.json()
        throw Error(r.error)
      }
      return response
    })
    .catch((err) => {
      throw err
    })
}


export const updateStore = async ({ storeId, name, type, status, address, trn, storeEnv, wallet }) => {

  const { token } = JSON.parse(localStorage.getItem("jwt"));


  let reqBody = {
    "name": name,
    "trn": trn,
    "type": type,
    "status": status,
    "address": address,
    "storeEnv": storeEnv,
    "wallet": wallet
  }


  return await fetch(`${API}/store/update/${storeId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify(reqBody)
  })
    .then(async (response) => {
      if (response.status !== 200) {
        let r = await response.json()
        throw Error(r.error)
      }
      return response.json()
    })
    .catch((err) => {
      return err
    })
}








