import React, { useState, useEffect } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { SlideUpTransition } from "../../../core/mui-transitions"

import { Form, Formik } from "formik"
import { updateStore } from "../data/remote/Stores"
import { StoreTypes } from "../constants/constants"
import { StoreStatus } from "../../../constants"
import { socket } from "../../../services/socketio"
import { getAllStoreEnv } from "../data/remote/StoreEnv"


export default function useUpdateStoreDialog() {


  const [isLoading, setisLoading] = useState(true)

  const [show, setshow] = useState(false)

  const [selectedStore, setselectedStore] = useState({})

  const showUpdateStoreDialog = (storeData) => {
    setselectedStore(storeData)
    setshow(true)
  }

  const closeUpdateStoreDialog = () => {
    setshow(false)
  }

  const UpdateStoreDialog = ({ onSuccess = () => { } }) => {

    let { name, type, address, status, trn, storeEnv, wallet } = selectedStore

    const [storeEnvList, setstoreEnvList] = useState([])


    const initialFunction = async () => {
      let result = await getAllStoreEnv()
      setisLoading(false)
      setstoreEnvList(result)
    }

    useEffect(() => {
      initialFunction()
    }, [])

    return (
      <Dialog open={show} onClose={closeUpdateStoreDialog} TransitionComponent={SlideUpTransition} fullWidth>
        <DialogContent>
          <Typography variant={"h5"}>Update Store</Typography>
        </DialogContent>
        <Formik
          initialValues={{
            name: name,
            type: type,
            status: status,
            trn: trn,
            addressl1: address?.split("|")[0],
            city: address?.split("|")[1],
            state: address?.split("|")[2],
            zipcode: address?.split("|")[3],
            storeEnv: storeEnv?._id,
            walletLimit: wallet?.limit,
            walletBalance: wallet?.balance
          }}
          onSubmit={async (data) => {
            try {
              let storeparams = {
                storeId: selectedStore?._id,
                name: data?.name,
                trn: data?.trn,
                type: data?.type,
                status: data.status,
                address: `${data?.addressl1}|${data?.city}|${data?.state}|${data?.zipcode}`,
                storeEnv: data?.storeEnv,
                wallet: {
                  limit: data.walletLimit,
                  balance: data.walletBalance,
                  // history: [{}]
                }
              }

              let newStoreData = await updateStore(storeparams)

              console.log(newStoreData)

              //todo: move this to the endpoint - (`${API}/store/update/${storeId already has??
              socket.emit("emit-new-storedata", {
                ...newStoreData,
              });

              onSuccess()

              closeUpdateStoreDialog()

            } catch (error) {
              throw error
            }
          }}>{({ handleChange, isSubmitting, values }) => {
            return (
              <DialogContent>
                <Form>
                  <Stack spacing={2}>

                    <TextField
                      required
                      name={"name"}
                      label="Store name"
                      placeholder="Enter store name"
                      onChange={handleChange}
                      value={values.name}
                    />
                    <FormControl required>
                      <InputLabel id="demo-simple-select-helper-label">
                        {"Store Type"}
                      </InputLabel>
                      <Select
                        value={values.type}
                        required
                        name={"type"}
                        label={"Store type"}
                        placeholder={"Select store type"}
                        onChange={handleChange}
                      >
                        {StoreTypes.map((v) => (
                          <MenuItem value={v.split(":")[0]}>{v.split(":")[1]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      name={"trn"}
                      label="Store TRN"
                      placeholder="Enter store TRN"
                      onChange={handleChange}
                      value={values.trn}
                    />
                    <FormControl required>
                      <InputLabel id="demo-simple-select-helper-label">
                        {"Store Status"}
                      </InputLabel>
                      <Select
                        value={values.status}
                        required
                        name={"status"}
                        label={"Store Status"}
                        placeholder={"Select store status"}
                        onChange={handleChange}
                      >
                        {StoreStatus.map((v) => (
                          <MenuItem value={v.split(":")[1]}>{v.split(":")[0]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      required
                      name={"addressl1"}
                      label="Address line 1"
                      onChange={handleChange}
                      value={values.addressl1}
                    />
                    <TextField
                      required
                      name={"city"}
                      label="City"
                      onChange={handleChange}
                      value={values.city}
                    />
                    <TextField
                      required
                      name={"state"}
                      label="State"
                      onChange={handleChange}
                      value={values.state}
                    />
                    <TextField
                      required
                      name={"zipcode"}
                      label="Zip code"
                      onChange={handleChange}
                      value={values.zipcode}
                    />
                    <FormControl required>
                      <InputLabel id="demo-simple-select-helper-label">
                        {"Store Environment"}
                      </InputLabel>
                      <Select
                        value={values.storeEnv}
                        required
                        name={"storeEnv"}
                        label={"Store Environment"}
                        placeholder={"Select store environment"}
                        onChange={handleChange}
                      >
                        {storeEnvList.map((v) => (
                          <MenuItem value={v._id}>{v.environmentName} - {v.currency}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      required
                      name={"walletLimit"}
                      label="Wallet Limit"
                      onChange={handleChange}
                      value={values.walletLimit}
                      helperText={`Following the currency of Store Env - ${
                        storeEnvList.map((v) => {
                          if(v._id === values.storeEnv) {
                            return v.currency;
                          } else {
                            return ""
                          }
                          // remove other array on string render
                        })
                      }, -1 means unlimited`}
                    />

                    <TextField
                      required
                      name={"walletBalance"}
                      label="Wallet Balance"
                      onChange={handleChange}
                      value={values.walletBalance}
                      helperText={`Following the currency of Store Env - ${
                        storeEnvList.map((v) => {
                          if(v._id === values.storeEnv) {
                            return v.currency;
                          } else {
                            return ""
                          }
                          // remove other array on string render
                        })
                      }`}
                    />

                  </Stack>
                  <DialogActions>
                    <Button disabled={isSubmitting} type={"submit"}>{isSubmitting ? "Please wait..." : "UPDATE STORE"}</Button>
                  </DialogActions>
                </Form>
              </DialogContent>
            )
          }}</Formik>

      </Dialog>
    )
  }

  return { showUpdateStoreDialog, closeUpdateStoreDialog, UpdateStoreDialog }
}