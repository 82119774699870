
import React from 'react'

import { Button, Card, CardContent, Divider, Stack, Typography } from '@mui/material'


const ValueDisplayCard = ({ title = "Title", value = "VALUE", actionComponent = <Button>ACTION</Button> }) => {
  return (
    <Card>
      <CardContent>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack>
            <Typography>
              {title}
            </Typography>
            <Typography>
              <strong>{value}</strong>
            </Typography>
          </Stack>
          {actionComponent}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ValueDisplayCard