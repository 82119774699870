import React, { useState, useEffect } from 'react'

import { Alert, AlertTitle, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { SlideUpTransition } from '../../../core/mui-transitions'
import { Box } from '@mui/system'
import { getAllStores } from '../../stores/data/remote/Stores'
import CustomLoader from '../../../core/CustomLoader'
import { signup } from '../../../auth'
import PhoneTextfield from '../../../core/PhoneTextfield'
import CloseIcon from '@mui/icons-material/Close';

export default function useAddNewCashier() {

  const [show, setshow] = useState(false)

  const showAddNewCashier = () => {
    setshow(true)
  }

  const closeAddNewCashier = () => {
    setshow(false)
  }


  const AddNewCashier = ({ onSuccess = () => { } }) => {

    const [isLoading, setisLoading] = useState(true)

    const [stores, setstores] = useState([])

    const [alertState, setAlertState] = useState({
      open: false,
      severity: "success",
      message: "We are trying to tell you something"
    })

    useEffect(() => {
      getAllStores().then(result => {
        setstores(result)
        setisLoading(false)
      })
    }, [])

    return <Dialog open={show} onClose={closeAddNewCashier} fullWidth TransitionComponent={SlideUpTransition}>
      <DialogTitle>Please fill details</DialogTitle>
      <Box sx={{ height: 1 }} />
      <DialogContent>
        {isLoading && <CustomLoader />}
        {
          !isLoading && <Formik
            initialValues={{}}
            onSubmit={async (data) => {
              try {

                let userData = {
                  store: data?.store,
                  name: `${data?.fname} ${data?.lname}`,
                  email: data?.email,
                  phone: data?.phone,
                  password: '123456'
                }


                await signup(userData)


                onSuccess()

                closeAddNewCashier()
              } catch (error) {
                setAlertState((prevState) => ({
                  ...prevState,
                  open: true,
                  severity: "error",
                  message: `${error}`
                }))
              }
            }}
          >{({ handleChange, isSubmitting, values }) => {
            return (
              <Form>
                <Stack spacing={2}>
                  <FormControl required>
                    <InputLabel id="demo-simple-select-helper-label">
                      {"Assigned Store"}
                    </InputLabel>
                    <Select
                      value={values.type}
                      required
                      name={"store"}
                      label={"Assigned Store"}
                      placeholder={"Select store"}
                      onChange={handleChange}
                    >
                      {stores.map((v) => (
                        <MenuItem value={v?._id}>
                          <Stack>
                            <Typography>{v?.name}</Typography>
                            <Typography fontSize={10} sx={{ color: "gray" }}>{v?.type}</Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    required
                    name={"fname"}
                    label={"First name"}
                    onChange={handleChange}
                  />
                  <TextField
                    required
                    name={"lname"}
                    label={"Last name"}
                    onChange={handleChange}
                  />
                  <TextField
                    required
                    name={"email"}
                    label={"Email name"}
                    onChange={handleChange}
                  />
                  <PhoneTextfield
                    name={"phone"}
                    label={"Mobile number"}
                    placeholder={"Enter mobile number"}
                  />
                  <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    Default password is — <strong>123456</strong>, <br />
                    they will be forced to change it once they login for the first time
                  </Alert>

                  <Collapse in={alertState.open}>
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setAlertState((prevState) => ({
                              ...prevState,
                              open: false,

                            }))
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      severity={alertState.severity}
                      sx={{ mb: 2 }}
                    >
                      {alertState.message}
                    </Alert>
                  </Collapse>
                </Stack>
                <DialogActions>
                  <Button disabled={isSubmitting} type={"submit"}>{isSubmitting ? "Please wait" : "ADD CASHIER"}</Button>
                </DialogActions>
              </Form>
            )
          }}</Formik>
        }
      </DialogContent>
    </Dialog>

  }

  return { showAddNewCashier, closeAddNewCashier, AddNewCashier }
}