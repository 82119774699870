import React, { useState, useEffect } from 'react'

import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { SlideUpTransition } from '../../../core/mui-transitions'
import { Box } from '@mui/system'
import { getAllStores } from '../../stores/data/remote/Stores'
import CustomLoader from '../../../core/CustomLoader'
import { updateUser } from '../data/users'
import PhoneTextfield from '../../../core/PhoneTextfield'
import CloseIcon from '@mui/icons-material/Close';
import { CashierStatus } from '../../../constants'
import socket from '../../../services/socketio'

export default function useUpdateCashier() {

  const [show, setshow] = useState(false)

  const [selectedCashier, setSelectedCashier] = useState({})

  const showUpdateCashier = (cashierData) => {
    setshow(true)
    setSelectedCashier(cashierData)
  }

  const closeUpdateCashier = () => {
    setshow(false)
  }


  const UpdateCashier = ({ onSuccess = () => { } }) => {



    const [isLoading, setisLoading] = useState(true)

    const [stores, setstores] = useState([])

    const [alertState, setAlertState] = useState({
      open: false,
      severity: "success",
      message: "We are trying to tell you something"
    })

    useEffect(() => {
      getAllStores().then(result => {
        setstores(result)
        setisLoading(false)
      })
    }, [])

    let { _id, status, store, name, email, phone } = selectedCashier

    return <Dialog open={show} onClose={closeUpdateCashier} fullWidth TransitionComponent={SlideUpTransition}>
      <DialogTitle>Please fill details</DialogTitle>
      <Box sx={{ height: 1 }} />
      <DialogContent>
        {isLoading && <CustomLoader />}
        {
          !isLoading && <Formik
            initialValues={{
              status: status,
              store: store?._id,
              fname: name?.split(" ")[0],
              lname: name?.split(" ")[1],
              email: email,
              phone: phone,
            }}
            onSubmit={async (data) => {

              try {

                let userData = {
                  status: data?.status,
                  store: data?.store,
                  name: `${data?.fname} ${data?.lname}`,
                  email: data?.email,
                  phone: data?.phone,
                }


                let newUserData = await updateUser({ userId: _id, updateData: userData })

                socket.emit("emit-new-userdata", {
                  ...newUserData,
                });

                onSuccess()

                closeUpdateCashier()
              } catch (error) {
                setAlertState((prevState) => ({
                  ...prevState,
                  open: true,
                  severity: "error",
                  message: `${error}`
                }))
              }
            }}
          >{({ handleChange, isSubmitting, values }) => {
            return (
              <Form>
                <Stack spacing={2}>
                  <FormControl required>
                    <InputLabel id="demo-simple-select-helper-label">
                      {"Assigned Store"}
                    </InputLabel>
                    <Select
                      value={values.store}
                      required
                      name={"store"}
                      label={"Assigned Store"}
                      placeholder={"Select store"}
                      onChange={handleChange}
                    >
                      {stores.map((v) => (
                        <MenuItem value={v?._id}>
                          <Stack>
                            <Typography>{v?.name}</Typography>
                            <Typography fontSize={10} sx={{ color: "gray" }}>{v?.type}</Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl required>
                    <InputLabel id="demo-simple-select-helper-label">
                      {"Status"}
                    </InputLabel>
                    <Select
                      value={values.status}
                      required
                      name={"status"}
                      label={"Status"}
                      placeholder={"Select status"}
                      onChange={handleChange}
                    >
                      {CashierStatus.map((v) => (
                        <MenuItem value={v.split(":")[1]}>{v.split(":")[0]}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    required
                    name={"fname"}
                    label={"First name"}
                    onChange={handleChange}
                    value={values.fname}
                  />
                  <TextField
                    required
                    name={"lname"}
                    label={"Last name"}
                    onChange={handleChange}
                    value={values.lname}
                  />
                  <TextField
                    required
                    name={"email"}
                    label={"Email name"}
                    onChange={handleChange}
                    value={values.email}
                  />
                  <PhoneTextfield
                    name={"phone"}
                    label={"Mobile number"}
                    placeholder={"Enter mobile number"}
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <Collapse in={alertState.open}>
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setAlertState((prevState) => ({
                              ...prevState,
                              open: false,

                            }))
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      severity={alertState.severity}
                      sx={{ mb: 2 }}
                    >
                      {alertState.message}
                    </Alert>
                  </Collapse>
                </Stack>
                <DialogActions>
                  <Button disabled={isSubmitting} type={"submit"}>{isSubmitting ? "Please wait" : "UPDATE CASHIER"}</Button>
                </DialogActions>
              </Form>
            )
          }}</Formik>
        }
      </DialogContent>
    </Dialog>

  }

  return { showUpdateCashier, closeUpdateCashier, UpdateCashier }
}