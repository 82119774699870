import React from 'react'

import { Chip } from '@mui/material'


const StoreStatusChip = ({ status }) => {


  const labelInterpretter = (statusNumber) => {
    switch (statusNumber) {
      case 0:
        return "Inactive"
      case 1:
        return "Active"
      default:
        return "Unknown"
    }
  }


  const colorInterpretter = (statusNumber) => {
    switch (statusNumber) {
      case 0:
        return "error"
      case 1:
        return "success"
      default:
        return "warning"
    }
  }



  return (
    <Chip size="small" variant="filled" label={labelInterpretter(status)} color={colorInterpretter(status)} onClick={() => { }} />
  )
}

export default StoreStatusChip